
import { Clinic } from '@/modules/clinic'
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SalaryReport extends Vue {
  clinic = $app.module(Clinic);

  salary: any = null;
  loading = false;

  get showMonthSalary() {
    return this.salary.dayCount !== undefined && this.salary.dayCount > 0 &&
      this.salary.workedDays !== undefined && this.salary.workedDays > 0 &&
      this.salary.monthRate !== undefined && this.salary.monthRate > 0;
  }

  get showHourSalary() {
    return this.salary.workedHours !== undefined && this.salary.workedHours > 0 &&
      this.salary.hourRate !== undefined && this.salary.hourRate > 0;
  }

  get monthSalary() {
    return this.showMonthSalary
      ? this.salary.workedDays / this.salary.dayCount * this.salary.monthRate * 1.00
      : 0.00;
  }

  get hourSalary() {
    return this.showHourSalary
      ? this.salary.hourRate * this.salary.workedHours * 1.00
      : 0.00;
  }

  get additional() {
    let sum = 0.00;
    if (this.salary.additional !== undefined) {
      this.salary.additional.forEach((item: any) => { sum = sum + item.value });
    }
    return sum;
  }

  get total() {
    return this.monthSalary + this.hourSalary + this.additional;
  }

  async load() {
    this.loading = true;
    try {
      this.salary = await $app.get('/api/clinics/salary/report', { UserId: this.$route.query.UserId, ReportDate: this.$route.query.ReportDate });
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
    this.loading = false;
  }

  mounted() {
    this.load();
  }
}
